const getSubdomain = () => {
  const { hostname } = window.location
  const parts = hostname.split('.')
  return parts.length >= 2 ? parts[0] : ''
}

const getDomain = () => {
  const { hostname } = window.location
  const parts = hostname.split('.')
  return parts.length > 2 ? parts.slice(1).join('.') : hostname
}

const config = {
  development: {
    baseUrl: `http://${getSubdomain()}.localhost:8080`,
    agency: getSubdomain(),
  },
  staging: {
    baseUrl: `https://${getSubdomain()}.dev.${getDomain()}`,
    agency: `${getSubdomain()}.${getDomain()}`,
  },
  production: {
    baseUrl: `https://${getSubdomain()}.${getDomain()}`,
    agency: `${getSubdomain()}.${getDomain()}`,
  },
}

const env = process.env.VUE_APP_ENV || 'asdf'

export default config[env]
